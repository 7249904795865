// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
// @material-ui/icons
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EmojiPeopleIcon from '@material-ui/icons/People';
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Button from 'components/CustomButtons/Button'
import React, { useEffect } from "react";

import styles from "../../assets/jss/nextjs-material-kit/pages/landingPageSections/productStyle.js";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import InfoArea from "../../components/InfoArea/InfoArea.js";

const useStyles = makeStyles(styles);

const adequateDonations = `
              We want to ensure that the person receiving the shoes has an experience as if they were shopping for a pair of shoes. We are asking
            for our community members to provide gently worn shoes, without holes, with laces, and with intact soles.`

const partners = `We have been fortunate to partner with Salesforce, Portland Trailblazers, Trailblazers Boys & Girls Club, Sneaker Week Pdx and Century Bar PDX. These strategic partners have provided an outlet and resources for Sidewalk to receive shoes by donation as well as provide donations through collaborative efforts`

const whereToDonate = `Sidewalk currently has the following locations where you can donate in the Portland area:
Suite: Sidewalk.PDX 4506 NE Martin Luther King Jr Blvd, Portland, OR 97211 and
4610 SE Belmont St. Portland, OR 97215 YWCA c/o Sidewalk.PDX`

const whoWeServe = `
Sidewalk is passionate in providing underserved youth in the Portland metro area with adequate footwear. Our services help the youth with avoiding foot disease, mental health improvement, and breaking barriers to sport. These participants range from infants to seniors in High School.`

export default function DescriptionSection() {
  const classes = useStyles();

  const script = document.createElement('script');
  script.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js";
  script.async = true;

  useEffect(() => {
     window.bboxInit = function () { window.bbox.showForm('c5f4264f-4da7-4fe7-9500-49124464591a') }
  }, [])

  const getForm = () => document.body.appendChild(script)

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>

          <h2 className={classes.title} style={{color: 'black'}}><img src={require('assets/img/sw-logo.png')} width='150px' alt='sidewalk' /></h2>

          {/* <h5 className={classes.description}></h5> */}
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Donations"
              description={adequateDonations}
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Partners"
              description={partners}
              icon={GroupWorkIcon}
              // iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Who We Serve"
              description={whoWeServe}
              icon={EmojiPeopleIcon}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Where To Donate"
              description={whereToDonate}
              icon={LocationOnIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="How To Donate"
              icon={AttachMoneyIcon}
              iconColor="success"
              vertical
            />
            <h5><a onClick={getForm} style={{cursor: 'pointer'}}>
              <Button
                color="primary"
                onClick={getForm}
                className={classes.navLink}
              >
                Donate
              </Button>
            </a></h5>
          </GridItem>
        </GridContainer>
      </div>
      <div style={{display: 'flex', width:'100%', justifyContent: 'center'}} id="bbox-root"></div>
    </div>
  );
}
