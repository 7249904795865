import "assets/scss/nextjs-material-kit.scss?v=1.0.0"
import 'firebase/performance';
import 'firebase/firestore';

import Footer from 'components/Footer/Footer'
import React from "react";
import { HashRouter } from 'react-router-dom'
import {
  FirebaseAppProvider,
  SuspenseWithPerf,
} from 'reactfire';

import routes from '../routes'

const firebaseConfig = {
  apiKey: "AIzaSyAhPD3gXTxbpl1EbfmYtL8i6WWNvozfvXk",
  authDomain: "sidewalk-pdx.firebaseapp.com",
  databaseURL: "https://sidewalk-pdx.firebaseio.com",
  projectId: "sidewalk-pdx",
  storageBucket: "sidewalk-pdx.appspot.com",
  messagingSenderId: "255809849828",
  appId: "1:255809849828:web:1483cdb226a668f2d19ff1"
}


export default function () {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} initPerformance>
      <SuspenseWithPerf
        fallback={'loading sidewalk pdx'}
        traceId={'load-sidewalk-pdx'}
      >
        <HashRouter>
          {routes}
        </HashRouter>
      </SuspenseWithPerf>
      <Footer />
    </FirebaseAppProvider>
  )
}
