// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
import React, { useRef } from "react";
import {
  SuspenseWithPerf,
  useFirestore,
  useFirestoreCollection
} from 'reactfire';

import styles from "../../assets/jss/nextjs-material-kit/pages/landingPageSections/teamStyle.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
// import Button from "../../components/CustomButtons/Button.js";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

const useStyles = makeStyles(styles);

const Partners = ({ classes, imageClasses }) => {
  const firestore = useFirestore();
  const partnersRef = firestore().collection('partners').orderBy('sequence')
  const partners = []

  useFirestoreCollection(partnersRef).forEach(snap => partners.push(snap.data()))

  return partners.map(partner => (
  <GridItem xs={12} sm={12} md={4} key={partner.name}>
    <Card plain>
      <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
        <img src={partner.src} alt="..." className={imageClasses} />
      </GridItem>
      <h4 className={classes.cardTitle}>
        { partner.name }
        <br />
        { partner.job && <small className={classes.smallTitle}>{ partner.job }</small> }
      </h4>
      <CardBody>
        { partner.link && <a href={partner.link} target='_blank' rel="noopener noreferrer">Website</a> }
        <p className={classes.description}>
          { partner.description }
        </p>
      </CardBody>
      <CardFooter className={classes.justifyCenter}>
        {/* <Button TODO: Use FA social components
          justIcon
          color="transparent"
          className={classes.margin5}
        >
          <i className={classes.socials + " fab fa-twitter"} />
        </Button> */}
        {/* <Button
          justIcon
          color="transparent"
          className={classes.margin5}
        >
          <i className={classes.socials + " fab fa-instagram"} />
        </Button> */}
        {/* <Button
          justIcon
          color="transparent"
          className={classes.margin5}
        >
          <i className={classes.socials + " fab fa-facebook"} />
        </Button> */}
      </CardFooter>
    </Card>
  </GridItem>
))
}

export default function TeamSection() {
  const partnersRef = useRef('partnersRef')
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgFluid
  )
  return (
    <SuspenseWithPerf
      fallback='loading partners'
      traceId='load-partners'
    >
      <div className={classes.section}>
        <h2 className={classes.title}>Partners</h2>
        <div ref={partnersRef}>
          <GridContainer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <Partners {...{ classes, imageClasses }} />
          </GridContainer>
        </div>
      </div>
    </SuspenseWithPerf>
  )
}
