// @material-ui/icons
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import profile from "assets/img/sidewalkshoes.PNG";
import styles from "assets/jss/nextjs-material-kit/pages/profilePage.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinksProfile.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import React, { useEffect } from "react";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  const classes = useStyles();
  const { ...rest } = props;
  // const imageClasses = classNames(
  //   classes.imgRaised,
  //   classes.imgRoundedCircle,
  //   classes.imgFluid
  // );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div style={{ backgroundColor: 'black' }}>
      <Header
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax responsive small image={require("assets/img/profile-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 style={{color: 'white'}} className={classes.title}>About Our Founder</h1>
              {/* <h4>
                ...
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container} style={{display: 'flex', flexDirection: 'row-reverse', flexWrap: 'wrap', padding: '1em', justifyContent: 'center'}}>
            <GridContainer justify="center" style={{ display: 'flex', flexDirection: 'row' }}>
              <GridItem xs={12} sm={12} md={6}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                  {/* <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', paddingBottom: '4em'}}> */}
                    <img src={profile} alt="..." height='500px' width='400px' style={{paddingBottom: '4em'}} />
                  {/* </div> */}
                  <div className={classes.name}>
                    <h3 style={{ paddingLeft: '1.25em'}} className={classes.title}>Sidney Walker</h3>
                    <h6 style={{margin: 'auto', paddingLeft: '3.25em'}}>Founder</h6>
                    {/* <Button justIcon link className={classes.margin5} href="https://twitter.com/sidewalkpdx"target="_blank">
                      <FontAwesomeIcon className={classes.socialIcons} icon={faTwitter} />
                    </Button> */}
                    <Button style={{margin: 'auto', paddingLeft: '2em'}} justIcon link className={classes.margin5} href="https://www.instagram.com/sidewalk.pdx" target="_blank">
                      <FontAwesomeIcon className={classes.socialIcons} icon={faFacebook} />
                    </Button>
                    <Button style={{margin: 'auto', paddingLeft: '2em'}} justIcon link className={classes.margin5} href="https://www.facebook.com/sidewalkpdx" target="_blank">
                      <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description} style={{ padding: '1em' }}>
              <p>Sid Walker is the Philanthropist who created Sidewalk as a way to provide less fortunate children with adequate footwear in the local community. He first gained notoriety for his local events in the summer of 2019. He partnered with Century Bar PDX which yielded 115 pairs of shoes donated at his first event. His next partnership was with Fortune 500 company SalesForce. SalesForce provided an opportunity for Sidewalk to speak at their Beaverton Hub location which resulted in receiving 80 pairs of shoes from their associates. Next Sidewalk had a great opportunity to partner with Sneaker Week PDX which holds an annual sneaker themed week catered to the Portland community which attracts a global audience. Sidewalk collected 130 pairs of shoes through this collaboration which provided children that participated in sneaker week with adequate footwear. </p>

              <p>Sid Walker began his career in the banking industry where he held different positions as a Mortgage Retention Officer, Personal Banker, and Relationship Manager for 8 years. He then provided Business Consultative services to private practice doctors in the Audiology space. With this experience this provided Sid with the ability to take these skills learned from the corporate world and apply it to helping his community through a non profit organization which birthed Sidewalk. Sid is dedicated to providing the local community with unwavering support through educational forums, social awareness events, and holistic wellness.</p>

              <p>Sid was born in Washington, DC and was raised in Prince George's County Maryland.  He is the youngest of 4. Sid grew up having a great relationship with his immediate family. As most inner city families, Sid didn't come from a wealthy household. He was only allowed one pair of shoes per school year. "Growing up in the DMV area was tough, it was all about high fashion and fresh feet. If you didn't have the proper attire you were automatically discluded from the in crowd, which could result in bullying" says Sid. Sid found an outlet in youth basketball which allowed him to become a team player and get cool gear for being an athlete. The extra shoes from sports allowed him to have more than one pair of shoes for the school year.</p>

              <p>His passion for sneakers came from watching NBA All-star Penny Hardaway wear the Foamposite One Royal Blue. After seeing Penny 's sweet finger roll, Sid instantly knew who his Basketball role model was and wanted to mimic his game after the Orlando Magic All-star guard. Most people fell in love with sneakers due to Michael Jordan, Sid had a different idle.</p>

              <p>Getting a new pair of shoes meant the world to Sid and he wanted to create that same experience for underserved youth by providing them with adequate footwear. Sid is thankful to be in a position to provide a service to our future generations. </p>
            </div>
          </div>
          <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "@SidewalkPDX",
                      // tabIcon: Palette,
                      tabContent: (
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1571.jpeg?alt=media&token=3009e314-e49d-4f89-8c97-14c2e903a97c'
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1574.jpeg?alt=media&token=084f7b36-3e2a-4b87-bb17-6da4f84dba49'
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1652.jpeg?alt=media&token=62cfbf85-b6e2-4733-bcfe-18622e4dba41'
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1662.jpeg?alt=media&token=2f908450-17b2-4265-91d3-854a6afa5292'
                              className={navImageClasses}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1656.jpeg?alt=media&token=d8f660e6-81d3-4ad9-b0fe-97b4b80578b3'
                              className={navImageClasses}
                            />
                            <img
                              alt="..."
                              src='https://firebasestorage.googleapis.com/v0/b/sidewalk-pdx.appspot.com/o/images%2FIMG_1665.jpeg?alt=media&token=57cb94cc-a671-46af-a0a8-b6048d9d65b4'
                              className={navImageClasses}
                            />
                          </GridItem>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </GridItem>
            </GridContainer>
        </div>
      </div>
    </div>
  );
}
