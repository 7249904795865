import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import styles from "assets/jss/nextjs-material-kit/components/headerLinksStyle.js";
import Button from "components/CustomButtons/Button.js";
import React, { useRef } from "react";

/*eslint-disable*/

const useStyles = makeStyles(styles);

const scrollToRef = (ref) => window.scrollTo(0, 1000)

export default function HeaderLinks(props) {
  const partnersRef = useRef('partnersRef')
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Button
          href="/"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          Home
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#/profile"
          color="transparent"
          // target="_blank"
          className={classes.navLink}
        >
          About Us
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => window.scrollTo(0, 1150)}
        >
          Featured Partners
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => window.scrollTo(0, 2550)}
        >
          Events
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          onClick={() => window.scrollTo(0, 20000)}
        >
          contact
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          href='/#/gallery'
        >
          Gallery
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/#/donate"
          color="primary"
          className={classes.registerNavLink}
          round
        >
          Donate
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={'top'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/sidewalkpdx"
            target="_blank"
            className={classes.navLink}
          >
            <FontAwesomeIcon className={classes.socialIcons} icon={faFacebook} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={'top'}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/sidewalk.pdx"
            target="_blank"
            className={classes.navLink}
          >
            <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} />
          </Button>
        </Tooltip>
      </ListItem>
    </List>
  );
}
