import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/nextjs-material-kit/pages/profilePage.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinksProfile.js";
import InstagramGallery from "components/InstagramGallery/InstagramGallery";
import Parallax from "components/Parallax/Parallax.js";
import React from "react";

const useStyles = makeStyles(styles);

// This can be retrieved with a GET https://www.instagram.com/web/search/topsearch/?context=blended&query=INSTAGRAM_USERNAME
const INSTAGRAM_ID = "3116057297";
const THUMBNAIL_WIDTH = 640;
const PHOTO_COUNT = 60;

const Gallery = (props) => {
  const classes = useStyles()
  return (
    <>
      <Header
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          // {...rest}
        />
        <Parallax responsive small image={require("assets/img/profile-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 style={{color: 'white'}} className={classes.title}>Fresh Feet</h1>
              {/* <h4>
                ...
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <InstagramGallery
        userId={INSTAGRAM_ID}
        thumbnailWidth={THUMBNAIL_WIDTH}
        photoCount={PHOTO_COUNT}
      />
    </>
  );
}

export default Gallery
