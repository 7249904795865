// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useFirestore } from 'reactfire';

import styles from "../../assets/jss/nextjs-material-kit/pages/landingPageSections/workStyle.js";
import Button from "../../components/CustomButtons/Button.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";

const useStyles = makeStyles(styles);

const emailRequestObject = ({ name, email, message }) => ({
  from: email,
  to: 'sid@sidewalkpdx.com',
  message: {
    subject: `Sidewalk PDX Contact Form - ${name}`,
    html: message
  }
})

export default function WorkSection() {
  const classes = useStyles();
  const { handleSubmit, control, reset } = useForm();
  const firestore = useFirestore();

  const onSubmit = values => {
    const email = emailRequestObject(values)
    const mailRef = firestore().collection('mail')
    mailRef.add(email).then(() => {
      reset({
        name: "",
        email: "",
        message: ""
      })
      window.alert('Message Successfully Sent!')
    })
  };
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Want to get involved?</h2>
          {/* <h4 className={classes.description}>
            Divide details about your product or agency work into parts. Write a
            few lines about each one and contact us about any further
            collaboration. We will respond and get back to you in a couple of
            hours.
          </h4> */}
          <form id='sidewalk-contact-form' onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Controller name='name' control={control} as={<CustomInput
                  labelText="Your Name"
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                />} />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <Controller name='email' control={control} as={<CustomInput
                  name='email'
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                />} />
              </GridItem>
              <Controller name='message' control={control} as={<CustomInput
                name='message'
                labelText="Your Message"
                id="message"
                formControlProps={{
                  fullWidth: true,
                  className: classes.textArea
                }}
                inputProps={{
                  multiline: true,
                  rows: 5
                }}
              />} />
              <GridItem xs={12} sm={12} md={4} className={classes.textCenter}>
                <Button color="primary" type="submit">Send Message</Button>
              </GridItem>
            </GridContainer>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
