// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import sidewalkVideo from 'assets/img/sidewalk-pdx.mov'
// nodejs library that concatenates classes
import classNames from "classnames";
import SectionCarousel from "pages-sections/Components-Sections/SectionCarousel.js";
import React from "react";

import styles from "../assets/jss/nextjs-material-kit/pages/landingPage.js";
import Footer from "../components/Footer/Footer.js";
// core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinks.js";
// Sections for this page
import ProductSection from "../pages-sections/LandingPage-Sections/ProductSection.js";
import TeamSection from "../pages-sections/LandingPage-Sections/TeamSection.js";
import WorkSection from "../pages-sections/LandingPage-Sections/WorkSection.js";

// @material-ui/icons




const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', background: 'black' }}>
      <Header
        color="black"
        routes={dashboardRoutes}
        brand="Sidewalk PDX"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <video className='videoTag' autoPlay loop muted playsInline style={{ width: '100%' }}>
        <source src={sidewalkVideo} type='video/mp4' />
    </video>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
          <TeamSection />
          <SectionCarousel />
          <WorkSection />
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
