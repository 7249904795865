// material-ui core components
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import styles from "assets/jss/nextjs-material-kit/components/footerStyle.js";
// nodejs library that concatenates classes
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
// nodejs library to set properties for components
import PropTypes from "prop-types";
/*eslint-disable*/
import React from "react";

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  return (
    <footer className={footerClasses} style={{ backgroundColor: 'black', color: '#fff' }}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/#/donate"
                className={classes.block}
              >
                Donate
              </a>
            </ListItem>
                {/* <Button
                  href="https://twitter.com/sidewalkpdx"
                  target="_blank"
                  color="transparent"
                  className={classes.navLink}
                >
                  <FontAwesomeIcon className={classes.socialIcons} icon={faTwitter} />
                </Button> */}
                <Button
                  color="transparent"
                  href="https://www.facebook.com/sidewalkpdx"
                  target="_blank"
                  className={classes.navLink}
                >
                  <FontAwesomeIcon className={classes.socialIcons} icon={faFacebook} />
                </Button>
                <Button
                  color="transparent"
                  href="https://www.instagram.com/sidewalk.pdx"
                  target="_blank"
                  className={classes.navLink}
                >
                  <FontAwesomeIcon className={classes.socialIcons} icon={faInstagram} />
                </Button>
          </List>
        </div>
        <div style={{color: '#fff'}} className={classes.right}>
          Copyright &copy; {1900 + new Date().getYear()} Sidewalk PDX, All Rights Reserved - made with{" "}
          <Favorite className={classes.icon} /> by{" "}
          <a
            href="https://hiredesmondjones.com"
            style={{color: '#BF091B', fontWeight: '500'}}
            className={aClasses}
            target="_blank"
          >
            Pacific North Web Development
          </a>{" "}
          {/* for a better web. */}
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
