import React from 'react'
import { Route } from 'react-router-dom'

import ComponentsPage from './pages/components'
import DonationsPage from './pages/donations'
import GalleryPage from './pages/gallery'
import LandingPage from './pages/landing'
import ProfilePage from './pages/profile'

export default (
  <>
    <Route exact path='/' component={LandingPage} />
    <Route path='/home' component={LandingPage} />
    <Route path='/profile' component={ProfilePage} />
    <Route path='/donate' component={DonationsPage} />
    <Route path='/donations' component={DonationsPage} />
    <Route path='/dev/components' component={ComponentsPage} />
    <Route path='/gallery' component={GalleryPage} />
  </>
)
