import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import Fingerprint from "@material-ui/icons/Fingerprint";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import React from "react";

import styles from "../../assets/jss/nextjs-material-kit/pages/landingPageSections/productStyle.js";
// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import InfoArea from "../../components/InfoArea/InfoArea.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>

          <h2 className={classes.title} style={{color: 'black'}}><img src={require('assets/img/sw-logo.png')} width='150px' alt='sidewalk' /></h2>

          <h5 className={classes.description}>
            Sidewalk is a non-profit organization that focuses on getting shoes into the hands of those who need it most. A soul’s purpose is to walk the journey in soles comfortable enough to tell our stories. Through art, passion and style, every shoe has carried a person’s dream by being the foundation we walk on. Repurpose a journey by giving back to the communities it began at.

Sidewalk is derived from the owners name Sidney Walker. Walker has converted his immense passion for sneakers into a passion for providing to those in need. Sidewalk creates an experience through events, donations and social awareness with a goal of supplying wearable sneakers to those who are less fortunate.
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        {/* <GridContainer>
          <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
        </GridContainer> */}
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Free Chat"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Chat}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Verified Users"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Fingerprint"
              description="Divide details about your product or agency work into parts. Write a few lines about each one. A paragraph describing a feature will be enough."
              icon={Fingerprint}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer> */}
      </div>
    </div>
  );
}
