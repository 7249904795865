// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import LocationOn from "@material-ui/icons/LocationOn";
// import image1 from "assets/img/event1.jpg";
// import image2 from "assets/img/event2.jpg";
import image3 from "assets/img/event3.jpg";
import styles from "assets/jss/nextjs-material-kit/pages/componentsSections/carouselStyle.js";
import Card from "components/Card/Card.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";

const useStyles = makeStyles(styles);

const events = [
  {
    src: image3,
    caption: 'Purchase Tickets',
    link: 'http://bit.ly/sidewalkpdx'
  }
]

const getEvents = () => events.map(({ src, caption, link }) => (
  <div onClick={() => window.open(link)}>
    <img src={src} alt="event slide" className="slick-image" style={{ cursor: 'pointer' }}/>
    <div className="slick-caption">
      <h4>
        <LocationOn className="slick-icons" />
        <a href={link} target='_blank' rel="noopener noreferrer" style={{ color: '#BF091B' }}>
          {caption}
        </a>
      </h4>
    </div>
  </div>
))

export default function SectionCarousel() {
  const classes = useStyles()
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  }
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} className={classes.section}>
    <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: '#444' }}>Events</h1>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                { getEvents() }
              </Carousel>
            </Card>
            <a
            style={{
              display: 'flex',
              fontWeight: '600',
              justifyContent: 'center',
              color: '#444'
              }} href='http://bit.ly/sidewalkpdx'
              target='_blank'
              rel='noopener noreferrer'
            >
                <h4 style=
                {{display: 'flex',
                fontWeight: '600',
                justifyContent: 'center',
                color: '#444',
                textDecoration: 'underline'
                }}
                >Click Here to Purchase Tickets Before January 18th!</h4>
                </a>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
