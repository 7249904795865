// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// nodejs library that concatenates classes
import classNames from "classnames";
import React, { useEffect } from "react";

import styles from "../assets/jss/nextjs-material-kit/pages/landingPage.js";
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
// core components
import Header from "../components/Header/Header.js";
import HeaderLinks from "../components/Header/HeaderLinksDonate";
import Parallax from "../components/Parallax/Parallax.js";
// Sections for this page
import DescriptionSection from "../pages-sections/DonationsPage-Sections/DescriptionSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function DonationsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="black"
        routes={dashboardRoutes}
        brand="Sidewalk PDX"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "black"
        }}
        {...rest}
      />
    <Parallax small responsive image={require("assets/img/profile-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 style={{color: 'white'}} className={classes.title}>Donations</h1>
              {/* <h4>
                We want to ensure that the person receiving the shoes has an experience as if they were shopping for a pair of shoes.
                We are asking for our community members to provide gently worn shoes, without holes, with laces, and with intact soles.
              </h4> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <DescriptionSection />
        </div>
      </div>
    </div>
  );
}
